import React from 'react';
import { Text } from '@GDM/Text';
import { Toggle } from '@GDM/Toggle';
import { type FieldValues, FieldPath, useController, UseControllerProps } from 'react-hook-form';

export type ControlledToggleProps<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>> = Omit<
  ToggleProps,
  'onChange' | 'value'
> &
  UseControllerProps<TFieldValues, TFieldName>;

type ToggleProps = React.ComponentProps<typeof Toggle>;

export const ControlledToggle = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>(
  props: ControlledToggleProps<TFieldValues, TFieldName>,
) => {
  const { field, fieldState } = useController({
    name: props.name,
    control: props.control,
    shouldUnregister: props.shouldUnregister,
    rules: props.rules,
    disabled: props.disabled,
  });

  return (
    <div>
      <Toggle {...props} value={field.value} onChange={field.onChange} />
      {fieldState.error && <Text className="mt-1" size="sm" text={fieldState.error?.message} type="danger" />}
    </div>
  );
};
