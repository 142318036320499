import React, { useMemo, useState } from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import {
  DateCell,
  NumberCell,
  Table,
  TableBody,
  TableHead,
  HeadCell,
  TableActions,
  TablePageSizeSelect,
  TablePagination,
} from '@GDM/Table';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  type ColumnDef,
  type InitialTableState,
} from '@tanstack/react-table';
import { getSymbol } from '@utils/currency/getSymbol';
import { granularityFormat } from './constants/granularities';
import type { NegativePrice } from './negative-prices.types';

export const HourlyNegativePricesTable = ({
  prices,
  isLoading,
  showInstallation,
}: {
  prices?: NegativePrice[];
  isLoading: boolean;
  showInstallation: boolean;
}) => {
  const columns = useColumns();
  const data = prices ?? [];
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const initialState = useMemo<InitialTableState>(
    () => ({
      sorting: [{ id: 'start_time', desc: false }],
    }),
    [],
  );

  const columnVisibility = useMemo(() => {
    return {
      installation: showInstallation,
    };
  }, [showInstallation]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState,
    onPaginationChange: setPagination,
    state: { pagination, columnVisibility },
  });

  return (
    <>
      <Table>
        <TableHead table={table} />
        <TableBody table={table} isLoading={isLoading} />
      </Table>

      <TableActions>
        <TablePageSizeSelect
          pageSize={pagination.pageSize}
          setPageSize={table.setPageSize}
          totalEntries={data.length}
        />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </>
  );
};

const useColumns = () => {
  return useMemo(
    () =>
      [
        {
          accessorKey: 'installation',
          header: 'common.installation',
          cell: ({ row: { original } }) =>
            original.installation ? (
              <MeterNameCell
                installation_name={original.installation.name}
                installation_energy={original.installation.energy}
              />
            ) : null,
        },
        {
          accessorKey: 'start_time',
          header: 'common.date',
          cell: (row) => <DateCell getValue={row.getValue} format={granularityFormat.hourly} />,
        },
        {
          accessorKey: 'price',
          header: () => <HeadCell label="monitoring.installation.revenue.spot_price" tooltip="€/MWh" />,
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
        {
          accessorKey: 'production',
          header: () => <HeadCell label="common.production" tooltip="kWh" />,
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
        {
          id: 'estimatedInterruptedProduction',
          accessorFn: (row) => row.productible - row.production,
          header: () => (
            <HeadCell
              label="common.estimated_interrupted_production"
              tooltip="common.estimated_interrupted_production_tooltip"
            />
          ),
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
        {
          accessorKey: 'estimated_cost',
          header: () => (
            <HeadCell label="common.estimated_cost_during_negative_price_hours" tooltip={getSymbol('EUR')} />
          ),
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
      ] satisfies ColumnDef<NegativePrice>[],
    [],
  );
};
